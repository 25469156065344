exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-categories-tsx": () => import("./../../../src/pages/categories.tsx" /* webpackChunkName: "component---src-pages-categories-tsx" */),
  "component---src-pages-ctx-tsx": () => import("./../../../src/pages/ctx.tsx" /* webpackChunkName: "component---src-pages-ctx-tsx" */),
  "component---src-pages-exchange-rate-differences-forecast-tsx": () => import("./../../../src/pages/exchange-rate-differences/forecast.tsx" /* webpackChunkName: "component---src-pages-exchange-rate-differences-forecast-tsx" */),
  "component---src-pages-exchange-rate-differences-interactive-brokers-dividends-tsx": () => import("./../../../src/pages/exchange-rate-differences/interactive-brokers/dividends.tsx" /* webpackChunkName: "component---src-pages-exchange-rate-differences-interactive-brokers-dividends-tsx" */),
  "component---src-pages-exchange-rate-differences-interactive-brokers-orders-tsx": () => import("./../../../src/pages/exchange-rate-differences/interactive-brokers/orders.tsx" /* webpackChunkName: "component---src-pages-exchange-rate-differences-interactive-brokers-orders-tsx" */),
  "component---src-pages-exchange-rate-differences-zero-tsx": () => import("./../../../src/pages/exchange-rate-differences/zero.tsx" /* webpackChunkName: "component---src-pages-exchange-rate-differences-zero-tsx" */),
  "component---src-pages-garage-allocator-tsx": () => import("./../../../src/pages/garage/allocator.tsx" /* webpackChunkName: "component---src-pages-garage-allocator-tsx" */),
  "component---src-pages-garage-bubble-tsx": () => import("./../../../src/pages/garage/bubble.tsx" /* webpackChunkName: "component---src-pages-garage-bubble-tsx" */),
  "component---src-pages-garage-compass-index-tsx": () => import("./../../../src/pages/garage/compass/index.tsx" /* webpackChunkName: "component---src-pages-garage-compass-index-tsx" */),
  "component---src-pages-garage-flip-the-coin-tsx": () => import("./../../../src/pages/garage/flip-the-coin.tsx" /* webpackChunkName: "component---src-pages-garage-flip-the-coin-tsx" */),
  "component---src-pages-garage-investing-clock-index-tsx": () => import("./../../../src/pages/garage/investing-clock/index.tsx" /* webpackChunkName: "component---src-pages-garage-investing-clock-index-tsx" */),
  "component---src-pages-garage-ten-days-tsx": () => import("./../../../src/pages/garage/ten-days.tsx" /* webpackChunkName: "component---src-pages-garage-ten-days-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-ovdp-index-tsx": () => import("./../../../src/pages/ovdp/index.tsx" /* webpackChunkName: "component---src-pages-ovdp-index-tsx" */),
  "component---src-pages-payment-systems-index-tsx": () => import("./../../../src/pages/payment-systems/index.tsx" /* webpackChunkName: "component---src-pages-payment-systems-index-tsx" */),
  "component---src-pages-rebalance-index-tsx": () => import("./../../../src/pages/rebalance/index.tsx" /* webpackChunkName: "component---src-pages-rebalance-index-tsx" */),
  "component---src-pages-reversal-exchange-rate-tsx": () => import("./../../../src/pages/reversal-exchange-rate.tsx" /* webpackChunkName: "component---src-pages-reversal-exchange-rate-tsx" */),
  "component---src-pages-sharpe-ratio-index-tsx": () => import("./../../../src/pages/sharpe-ratio/index.tsx" /* webpackChunkName: "component---src-pages-sharpe-ratio-index-tsx" */),
  "component---src-pages-ua-index-tsx": () => import("./../../../src/pages/ua/index.tsx" /* webpackChunkName: "component---src-pages-ua-index-tsx" */)
}

